.CodeMirror-info {
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  color: #555;
  font-family:
    system,
    -apple-system,
    'San Francisco',
    '.SFNSDisplay-Regular',
    'Segoe UI',
    Segoe,
    'Segoe WP',
    'Helvetica Neue',
    helvetica,
    'Lucida Grande',
    arial,
    sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 8px -8px;
  max-width: 400px;
  opacity: 0;
  overflow: hidden;
  padding: 8px 8px;
  position: fixed;
  transition: opacity 0.15s;
  z-index: 50;
}

.CodeMirror-info :first-child {
  margin-top: 0;
}

.CodeMirror-info :last-child {
  margin-bottom: 0;
}

.CodeMirror-info p {
  margin: 1em 0;
}

.CodeMirror-info .info-description {
  color: #777;
  line-height: 16px;
  margin-top: 1em;
  max-height: 80px;
  overflow: hidden;
}

.CodeMirror-info .info-deprecation {
  background: #fffae8;
  box-shadow: inset 0 1px 1px -1px #bfb063;
  color: #867F70;
  line-height: 16px;
  margin: -8px;
  margin-top: 8px;
  max-height: 80px;
  overflow: hidden;
  padding: 8px;
}

.CodeMirror-info .info-deprecation-label {
  color: #c79b2e;
  cursor: default;
  display: block;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  padding-bottom: 5px;
  text-transform: uppercase;
  user-select: none;
}

.CodeMirror-info .info-deprecation-label + * {
  margin-top: 0;
}

.CodeMirror-info a {
  text-decoration: none;
}

.CodeMirror-info a:hover {
  text-decoration: underline;
}

.CodeMirror-info .type-name {
  color: #CA9800;
}

.CodeMirror-info .field-name {
  color: #1F61A0;
}

.CodeMirror-info .enum-value {
  color: #0B7FC7;
}

.CodeMirror-info .arg-name {
  color: #8B2BB9;
}

.CodeMirror-info .directive-name {
  color: #B33086;
}
