.graphiql-container .doc-explorer {
  background: white;
}

.graphiql-container .doc-explorer-title-bar,
.graphiql-container .history-title-bar {
  cursor: default;
  display: flex;
  height: 34px;
  line-height: 14px;
  padding: 8px 8px 5px;
  position: relative;
  user-select: none;
}

.graphiql-container .doc-explorer-title,
.graphiql-container .history-title {
  flex: 1;
  font-weight: bold;
  overflow-x: hidden;
  padding: 10px 0 10px 10px;
  text-align: center;
  text-overflow: ellipsis;
  user-select: text;
  white-space: nowrap;
}

.graphiql-container .doc-explorer-back {
  color: #3B5998;
  cursor: pointer;
  margin: -7px 0 -6px -8px;
  overflow-x: hidden;
  padding: 17px 12px 16px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: 0;
  border: 0;
  line-height: 14px;
}

.doc-explorer-narrow .doc-explorer-back {
  width: 0;
}

.graphiql-container .doc-explorer-back:before {
  border-left: 2px solid #3B5998;
  border-top: 2px solid #3B5998;
  content: '';
  display: inline-block;
  height: 9px;
  margin: 0 3px -1px 0;
  position: relative;
  transform: rotate(-45deg);
  width: 9px;
}

.graphiql-container .doc-explorer-rhs {
  position: relative;
}

.graphiql-container .doc-explorer-contents,
.graphiql-container .history-contents {
  background-color: #ffffff;
  border-top: 1px solid #d6d6d6;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 20px 15px;
  position: absolute;
  right: 0;
  top: 47px;
}

.graphiql-container .doc-explorer-contents {
  min-width: 300px;
}

.graphiql-container .doc-type-description p:first-child ,
.graphiql-container .doc-type-description blockquote:first-child {
  margin-top: 0;
}

.graphiql-container .doc-explorer-contents a {
  cursor: pointer;
  text-decoration: none;
}

.graphiql-container .doc-explorer-contents a:hover {
  text-decoration: underline;
}

.graphiql-container .doc-value-description > :first-child {
  margin-top: 4px;
}

.graphiql-container .doc-value-description > :last-child {
  margin-bottom: 4px;
}

.graphiql-container .doc-type-description code,
.graphiql-container .doc-type-description pre,
.graphiql-container .doc-category code,
.graphiql-container .doc-category pre {
  --saf-0: rgba(var(--sk_foreground_low,29,28,29),0.13);
  font-size: 12px;
  line-height: 1.50001;
  font-variant-ligatures: none;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
}

.graphiql-container .doc-type-description code,
.graphiql-container .doc-category code {
  padding: 2px 3px 1px;
  border: 1px solid var(--saf-0);
  border-radius: 3px;
  background-color: rgba(var(--sk_foreground_min,29,28,29),.04);
  color: #e01e5a;
  background-color: white;
}

.graphiql-container .doc-category {
  margin: 20px 0;
}

.graphiql-container .doc-category-title {
  border-bottom: 1px solid #e0e0e0;
  color: #777;
  cursor: default;
  font-size: 14px;
  font-variant: small-caps;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0 -15px 10px 0;
  padding: 10px 0;
  user-select: none;
}

.graphiql-container .doc-category-item {
  margin: 12px 0;
  color: #555;
}

.graphiql-container .keyword {
  color: #B11A04;
}

.graphiql-container .type-name {
  color: #CA9800;
}

.graphiql-container .field-name {
  color: #1F61A0;
}

.graphiql-container .field-short-description {
  color: #999;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.graphiql-container .enum-value {
  color: #0B7FC7;
}

.graphiql-container .arg-name {
  color: #8B2BB9;
}

.graphiql-container .arg {
  display: block;
  margin-left: 1em;
}

.graphiql-container .arg:first-child:last-child,
.graphiql-container .arg:first-child:nth-last-child(2),
.graphiql-container .arg:first-child:nth-last-child(2) ~ .arg {
  display: inherit;
  margin: inherit;
}

.graphiql-container .arg:first-child:nth-last-child(2):after {
  content: ', ';
}

.graphiql-container .arg-default-value {
  color: #43A047;
}

.graphiql-container .doc-deprecation {
  background: #fffae8;
  box-shadow: inset 0 0 1px #bfb063;
  color: #867F70;
  line-height: 16px;
  margin: 8px -8px;
  max-height: 80px;
  overflow: hidden;
  padding: 8px;
  border-radius: 3px;
}

.graphiql-container .doc-deprecation:before {
  content: 'Deprecated:';
  color: #c79b2e;
  cursor: default;
  display: block;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  padding-bottom: 5px;
  text-transform: uppercase;
  user-select: none;
}

.graphiql-container .doc-deprecation > :first-child {
  margin-top: 0;
}

.graphiql-container .doc-deprecation > :last-child {
  margin-bottom: 0;
}

.graphiql-container .show-btn {
  -webkit-appearance: initial;
  display: block;
  border-radius: 3px;
  border: solid 1px #ccc;
  text-align: center;
  padding: 8px 12px 10px;
  width: 100%;
  box-sizing: border-box;
  background: #fbfcfc;
  color: #555;
  cursor: pointer;
}

.graphiql-container .search-box {
  border-bottom: 1px solid #d3d6db;
  display: block;
  font-size: 14px;
  margin: -15px -15px 12px 0;
  position: relative;
}

.graphiql-container .search-box-icon {
  cursor: pointer;
  display: block;
  font-size: 24px;
  position: absolute;
  top: -2px;
  transform: rotate(-45deg);
  user-select: none;
}

.graphiql-container .search-box .search-box-clear {
  background-color: #d0d0d0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  padding: 1px 5px 2px;
  position: absolute;
  right: 3px;
  top: 8px;
  user-select: none;
  border: 0;
}

.graphiql-container .search-box .search-box-clear:hover {
  background-color: #b9b9b9;
}

.graphiql-container .search-box > input {
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
  padding: 6px 24px 8px 20px;
  width: 100%;
}

.graphiql-container .error-container {
  font-weight: bold;
  left: 0;
  letter-spacing: 1px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(0, -50%);
}
