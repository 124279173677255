.graphiql-container .history-contents {
  font-family: 'Consolas', 'Inconsolata', 'Droid Sans Mono', 'Monaco', monospace;
}

.graphiql-container .history-contents {
  margin: 0;
  padding: 0;
}

.graphiql-container .history-contents li {
  align-items: center;
  display: flex;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
}

.graphiql-container .history-contents li button:not(.history-label) {
  display: none;
  margin-left: 10px;
}

.graphiql-container .history-contents li:hover button:not(.history-label),
.graphiql-container .history-contents li:focus-within button:not(.history-label) {
  display: inline-block;
}

.graphiql-container .history-contents input,
.graphiql-container .history-contents button {
  padding: 0;
  background: 0;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: 14px;
  color: inherit;
}

.graphiql-container .history-contents input {
  flex-grow: 1;
}

.graphiql-container .history-contents input::placeholder {
  color: inherit;
}

.graphiql-container .history-contents button {
  cursor: pointer;
  text-align: left;
}

.graphiql-container .history-contents .history-label {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
